<template>
  <header class="login-header bg-white">
    <div class="logo login-header__logo">
      <a class="link" href="index.html">
        <img class="img" :src="currentLogo" alt />
      </a>
    </div>
    <section class="language login-header__language" style="direction: ltr">
      <button ref="en" class="button lang_btn language__english" @click="changeLang('en')">English</button>
      <button ref="ar" class="button lang_btn language__arabic" @click="changeLang('ar')">العربية</button>
      <button ref="he" class="button lang_btn language__hebrew" @click="changeLang('he')">עברית</button>
    </section>
  </header>
</template>

<script>
import i18nService from "@/common/i18n.service.js";
export default {
  name: "AuthHeader",
  props: {
    msg: String
  },
  data: () => {
    return {
      enLogo: "/assets/img/logo/logo-enar.png",
      heLogo: "/assets/img/logo/logo-he.png",
      currentLogo: ""
    }
  },
  mounted() {
    const current_langId = i18nService.getActiveLanguage();
    this.$refs[current_langId].classList.add("language__english_active");
    if (this.$i18n.locale == "en") {
      document.getElementsByTagName("body")[0].classList.remove("rtl-type");
      document.getElementsByTagName("body")[0].classList.add("ltr-type");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("ltr-type");
      document.getElementsByTagName("body")[0].classList.add("rtl-type");
    }

    this.setLogo(this.$i18n.locale);  
    
    if(!localStorage.getItem("language")) {
      localStorage.setItem("language", "he");
    }
  },
  methods: {
    changeLang(langId) {
      this.$i18n.locale = langId;
      i18nService.setActiveLanguage(langId);
      document
        .querySelector(".language__english_active")
        .classList.remove("language__english_active");
      this.$refs[langId].classList.add("language__english_active");
      if (langId == "en") {
        document.getElementsByTagName("body")[0].classList.remove("rtl-type");
        document.getElementsByTagName("body")[0].classList.add("ltr-type");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("ltr-type");
        document.getElementsByTagName("body")[0].classList.add("rtl-type");
      }
      this.setLogo(langId);
    },
    setLogo(langId) {
      if (langId == "he") {
        this.currentLogo = this.heLogo;
      } else {
        this.currentLogo = this.enLogo;
      }
    }
  }
};
</script>

<style scoped>
.logo {
  height: unset;
}
</style>