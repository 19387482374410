<template>
  <div class="page app auth-container">
    <AuthHeader/>
    <router-view></router-view>
    <!-- <AuthFooter/> -->
  </div>
</template>

<script>
import AuthHeader from '../components/AuthHeader'
// import AuthFooter from '../components/AuthFooter'

export default {
  name: 'AuthContainer',
  components: {
      AuthHeader,
      // AuthFooter,
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-container {
  padding-top: 0 !important;
}
</style>